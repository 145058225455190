import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageContext, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { HeroDefault } from '../components/partials/common/hero';
import SectionColumns from '../components/sections/columns-section';
import Verticals from '../components/sections/verticals';
import RepeatingSections from '../components/sections/repeating-section';
import SectionFaq from '../components/sections/faq-section';
import ConversionSection from '../components/sections/conversion';
import DemoConversionSection from '../components/sections/conversion_demo';
import SectionCardSlider from '../components/sections/cards-slider-section';
import RoiSection from '../components/sections/roi-section';
import Shop from '../components/partials/pricing/shop';

const Features: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  console.log(localKey);
  const { t } = useTranslation(localKey);
  const { lang, alternateUrls } = pageContext;
  const conversionDemo = t('conversionDemo');

  let benefitsForYou = false;
  const listbenefitsForYou: IColumnList = t('benefitsForYou.columns', {
    returnObjects: true,
  });
  if (typeof listbenefitsForYou === 'object') benefitsForYou = true;

  let benefitsClients = false;
  const listBenefitsClients: IColumnList = t('benefitsClients.columns', {
    returnObjects: true,
  });
  if (typeof listBenefitsClients === 'object') benefitsClients = true;

  const sections: IColumnList = t('sections', {
    returnObjects: true,
  });

  const questions: IColumnList = t('faq.questions', {
    returnObjects: true,
  });

  let slider = false;
  const sliderCards: any = t('moreFromShore.cards', {
    returnObjects: true,
  });
  if (typeof sliderCards === 'object') slider = true;

  const mobileImagesHero: any = t('hero.image-mobile', {
    returnObjects: true,
  });

  let show_shop = false;
  if (localKey === 'merchandise' || localKey === 'accounting') {
    show_shop = true;
  }

  return (
    <Layout pageContext={pageContext} navClasses="" mainClasses="feature">
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroDefault
        translation={t}
        localKey={localKey}
        mobileImagesHero={mobileImagesHero}
      />
      {benefitsForYou && (
        <SectionColumns
          classes={t('benefitsForYou.classes')}
          title={t('benefitsForYou.title')}
          columns={listbenefitsForYou}
        />
      )}
      {benefitsClients && (
        <SectionColumns
          classes={t('benefitsClients.classes')}
          title={t('benefitsClients.title')}
          columns={listBenefitsClients}
        />
      )}
      {localKey !== 'app' &&
        localKey !== 'website' &&
        lang !== 'fr' &&
        lang !== 'en-US' &&
        lang !== 'de-CH' &&
        lang !== 'es' && (
          <RoiSection lang={lang} classes="has-background-new-purple" />
        )}
      <Verticals />
      <RepeatingSections data={sections} />
      <SectionFaq
        classes={t('faq.classes')}
        title={t('faq.title')}
        questions={questions}
      />
      {/* 
      merchandise
      accounting
       */}
      {show_shop &&
        lang !== 'en' &&
        lang !== 'fr' &&
        lang !== 'en-US' &&
        lang !== 'de-CH' &&
        lang !== 'es' && (
          <Shop
            data={t}
            title="Du möchtest dein Kassenzubehör <br> direkt online bestellen?"
          />
        )}

      {slider && (
        <SectionCardSlider
          slides={sliderCards}
          title={t('moreFromShore.title')}
        />
      )}
      {conversionDemo === 'true' ? (
        <DemoConversionSection />
      ) : (
        <ConversionSection ctaUrl={t('conversion.ctaUrl')} />
      )}
    </Layout>
  );
};

export default Features;
