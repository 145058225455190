import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CardBottomImage } from '../partials/common/elements/Card';

const Verticals = () => {
  const { t } = useTranslation('verticals');
  const lang = t('key');
  const cards: any = t('cards', {
    returnObjects: true,
  });

  return (
    <section className={`${t('classes')} section section-cards columns`}>
      <div className="container column is-9 is-12-mobile  is-12-tablet">
        <div className="columns is-centered">
          <div className="column is-7 ">
            <h2 className="title title-large has-text-centered text-black">
              {t('title')}
            </h2>
          </div>
        </div>
        <div className="columns is-centered">
          {Object.keys(cards).map((key) => {
            return <CardBottomImage card={cards[key]} key={key} lang={lang} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Verticals;
