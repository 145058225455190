import * as React from 'react';
import { FC } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { FullWidthCardsSlider } from '../partials/common/elements/Slider';
import { CardFloatImage } from '../partials/common/elements/Card';

const SectionCardSlider: FC<{
  slides: any;
  classes?: string;
  title: string;
}> = ({ title, slides, classes }) => {
  return (
    <section className={`${classes && classes} section section-slider-cards `}>
      <div className="container ">
        <div className="columns is-centered">
          <div className="column is-12 ">
            <h2 className="title title-large has-text-centered text-black">
              {title}
            </h2>
          </div>
          {isMobileOnly &&
            Object.keys(slides).map((key) => {
              const card = slides[key];
              return (
                <CardFloatImage card={card} key={key} classes="mobile_card" />
              );
            })}
        </div>
      </div>
      <div className="row">
        {!isMobileOnly && <FullWidthCardsSlider slides={slides} />}
      </div>
    </section>
  );
};

SectionCardSlider.defaultProps = {
  classes: ``,
};
export default SectionCardSlider;
